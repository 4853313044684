<template>
    <div class="cont-table">

        <div class="spin">
            <ion-spinner name="crescent"></ion-spinner>
        </div>

        <div class="row cont_tickets cont_imp_xml_file_sup">
            <div class=" " style="margin:0 auto;">
                <div class="card">

                    <div class="card-body" style="padding:0px">

                        <div class="table-responsive container-doc" style="">

                            <table id="myTable" style="margin-top:57px">
                                <thead>
                                    <tr>

                                        <th class='all'>
                                            <div class="cl_checkall">
                                                <ion-checkbox name='emailgestoreall' class='checkall' slot='start'>
                                                </ion-checkbox>
                                            </div>
                                        </th>
                                        <th class='all'>
                                            <div class='cl_num'>N.</div>
                                        </th>

                                        <th class='all'>Fornitore</th>
                                        <th class='all'>Data Emissione</th>
                                        <th class='all'>Data scadenza</th>
                                        <th class='all' style="text-align: right;">Importo (€)</th>
                                        <th class='all' style="text-align: center;">Nuovo</th>

                                    </tr>
                                </thead>

                                <tfoot>

                                    <tr>
                                        <th colspan="5">
                                        </th>
                                    </tr>
                                </tfoot>

                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="contpopupmultiupload" style="display:none">

            <template>
                <modal name="popupMultiUpload" :clickToClose="false" :width="421" :height="400">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Caricamento Fatture

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12">

                            <div class="headerTablePopup">

                                <div class="colHeaderCheckbox">&nbsp;</div>
                                <div class="colHeaderNum">N.</div>
                                <div class="colHeaderForn">Fornitore</div>
                                <div class="colHeaderDataFatt">Emissione</div>
                                <div class="colHeaderDataScad">Scadenza</div>
                                <div class="colHeaderDataImporto">Importo (€)</div>

                            </div>


                            <v-list class="listFile">

                                <v-list-tile v-for="el in xmlInvoices" :key="el.id">

                                    <div class="rowUploadFile">

                                        <div class="columnUploadFileSelect">
                                            <v-checkbox v-model="idFileSel" multiple :value="el.id"
                                                class="cl-checkbox " />
                                        </div>

                                        <div style="width:20px;color:black;margin-top: -5px;">
                                            {{ el.id }}
                                        </div>

                                        <div style="width:131px;color:black;margin-top: -5px;">
                                            {{ el.fornitore }}
                                        </div>

                                        <div style="width:66px;color:black;margin-top: -5px;">
                                            {{ el.data_fattura }}
                                        </div>

                                        <div style="width:66px;color:black;margin-top: -5px;">
                                            {{ el.data_scadenza }}
                                        </div>

                                        <div style="width:70px;color:black;margin-top: -5px;text-align: right;">
                                            {{ el.totale }}
                                        </div>



                                    </div>

                                </v-list-tile>

                            </v-list>


                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-upload-file" contain
                                    src="@/assets/btn_cancel3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Cancel" @click="btnCancelUploadFiles" />

                                <v-img alt="" class="btn-confirm-upload-file" contain
                                    src="@/assets/btn_confirm3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Confirm" @click="btnConfirmUploadFiles" />

                            </div>

                        </v-col>

                    </v-row>




                </modal>
            </template>

        </div>

        <div class="contpopupimp" style="display:none">

            <template>
                <modal name="popupImp" :clickToClose="false" :width="360" :height="85">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">



                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12"
                            style="text-align:center;color:black;font-size:12px;font-weight: bold;">

                            Importazione in corso

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">



                        </v-col>

                    </v-row>





                </modal>
            </template>

        </div>


        <div class="contpopupperiodo" style="display:none">

            <template>
                <modal name="popupPeriodo" :clickToClose="false" :width="246" :height="324">


                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important;font-weight:bold;margin-left:10px">

                            Periodo

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="periodoDal" label="Dal" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="periodoDal" no-title @input="showPicker = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>

                    <v-row>

                        <v-col cols="12" md="12" style="margin-top:10px!important">

                            <v-menu v-model="showPicker3" :close-on-content-click="false" transitions="scale-transition"
                                offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field id="fldInpData" v-model="periodoAl" label="Al" persistent-hint
                                        readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="periodoAl" no-title @input="showPicker3 = false" locale="it">
                                </v-date-picker>
                            </v-menu>

                        </v-col>

                    </v-row>


                    <v-row>

                        <v-col cols="12" md="12">

                            <div style="display:flex;justify-content:center">

                                <v-img alt="" class="btn-cancel-periodo" contain
                                    src="@/assets/btn_cancel3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Cancel" @click="btnCancelPeriodo" />

                                <v-img alt="" class="btn-reset-period" contain src="@/assets/reset.png" max-width="25"
                                    max-height="25" style="cursor:pointer;margin-right:10px;margin-top:3px"
                                    transition="scale-transition" title="Delete" @click="btnResetPeriodo" />

                                <v-img alt="" class="btn-confirm-periodo" contain
                                    src="@/assets/btn_confirm3_invoiceweb.png" max-width="30" max-height="30"
                                    style="cursor:pointer;color:black;margin-right:10px" transition="scale-transition"
                                    title="Confirm" @click="btnConfirmPeriodo" />

                            </div>

                        </v-col>

                    </v-row>




                </modal>
            </template>

        </div>


    </div>
</template>

<script>
import {
    bus
} from "../main";
import router from ".././router";

import apiinvoice from "../utils/invoice/apiinvoice";


export default {
    destroyed() {
        this.$root.$children[0].bnavbuttons = [];

        bus.$off("bottomIconClickEvent");
    },

    mounted: function () {


        /*eslint-disable no-undef*/

        this.$root.$children[0].nomeAziendaSelezionata = "";

        if (window.innerWidth <= 768) {

            $("#btnUserInfo").css("transform", "translateX(25px)");

        }

        this.$root.$children[0].checkLogout();

        window.curStatus = "DAIMP";


        var pointerVue = this;

        window.perpage = 30;
        bus.$off("bottomIconClickEvent");

        const oggi = new Date();
        const anno = oggi.getFullYear();
        const mese = String(oggi.getMonth() + 1).padStart(2, '0'); // I mesi partono da 0
        const giorno = String(oggi.getDate()).padStart(2, '0');

        const dataFormattata = `${anno}-${mese}-${giorno}`;

        window.fromPeriod = dataFormattata;
        window.toPeriod = dataFormattata;

        pointerVue.periodoDal = dataFormattata;
        pointerVue.periodoAl = dataFormattata;

        pointerVue.$root.$children[0].normalDiv1 = true;
        pointerVue.$root.$children[0].normalDiv2 = true;

        pointerVue.$root.$children[0].invoiceDiv1 = false;
        pointerVue.$root.$children[0].invoiceDiv2 = false;
        pointerVue.$root.$children[0].invoiceDiv3 = false;
        pointerVue.$root.$children[0].invoiceDiv4 = false;
        pointerVue.$root.$children[0].invoiceDiv3Agila = false;

        pointerVue.$root.$children[0].calcolatorDiv1 = false;

        var namesurname = window.$cookies.get("namesurname");

        pointerVue.$root.$children[0].curTitle = namesurname;
        pointerVue.$root.$children[0].invoiceTitle = "Importa Xml Fatture Fornitori";


        window.curSrcText = "";




        // inizializzazione valori
        window.dateEmissDa = window.$cookies.get("sel_filter_inv_date_emiss_from");
        window.dateEmissA = window.$cookies.get("sel_filter_inv_date_emiss_to");

        window.dateScadDa = window.$cookies.get("sel_filter_inv_date_scad_from");
        window.dateScadA = window.$cookies.get("sel_filter_inv_date_scad_to");


        pointerVue.$root.$children[0].valDateFrom = window.$cookies.get("sel_filter_inv_date_scad_from");
        pointerVue.$root.$children[0].valDateTo = window.$cookies.get("sel_filter_inv_date_scad_to");


        $(document).ready(
            function () {

                $("body").off();

            }
        );

        $(document).ready(function () {


            $('.container-doc').on('dragover', function (e) {
                e.preventDefault();
                e.stopPropagation();
                $(this).addClass("div-hover");
            });

            $('.container-doc').on('dragleave', function (e) {
                e.preventDefault();
                e.stopPropagation();
                $(this).removeClass("div-hover");
            });


            $('.container-doc').on('drop', function (e) {

                e.preventDefault();
                e.stopPropagation();
                $(this).removeClass("div-hover");

                console.log("files: ", e.originalEvent.dataTransfer.files);

                if (e.originalEvent.dataTransfer.files.length > 0) {


                    pointerVue.validateDocumentsMulti(e.originalEvent.dataTransfer.files);


                }
                else {

                    pointerVue.$swal({
                        icon: "error",
                        text: "Area errata dove trascinare i file",
                        showConfirmButton: false,
                        timer: 2000
                    });


                }


            });


            $('body').on('click', '.selperiodo', function () {

                $(".contpopupperiodo").show();

                pointerVue.$modal.show('popupPeriodo');

            });

            $('body').on('click', '.selfornitori', function () {

                router.push({
                    path: "/vediFattureFornitori"
                });


            });


            $('body').on('click', '.selaggiorna', function () {

                pointerVue.showSpinner = true;
                pointerVue.$root.$children[0].showProgress = true;

                window.table.ajax.reload();

            });



            $('body').on('click', '.checkall', function () {


                $(".val_status").prop("checked", $(this).prop("checked"));

                console.log("CHECKALL: ", $(this).prop("checked"));


                if ($(this).prop("checked")) {

                    $("tr").addClass("active-row");

                    $("tr").eq(0).removeClass("active-row");

                }

                if (!$(this).prop("checked")) {

                    $("tr").removeClass("active-row");


                }

                pointerVue.hideShowButtonsSelRow();


            });















        });




        bus.$on("bottomIconClickEvent", (data) => {
            //this.header = data;
            console.log(data);
            switch (data) {

                case "btn_delete":

                    this.deleteRow();

                    break;

                default:
                    break;

            }
        });



        this.setupButtonsGrid();


        window.start = 0;

        pointerVue.setupGrid();

        this.hideShowButtons(false);


        setTimeout(() => {

            $("th:first").removeClass("sorting_asc");


        }, 300);


    },
    data: () => ({

        periodoDal: null,
        periodoAl: null,

        idFileSel: [],


        fileUpload: [],

        xmlInvoices: [],

        fileAccettati: ".xml",

        textSearch: "",

        selPag: null,

        dataScadA: null,
        dataScadDa: null,
        showPicker2: null,

        dataEmissA: null,
        dataEmissDa: null,
        showPicker3: null,
        showPicker4: null,

        allPayments: [
            {
                img: 'https://app.emtool.eu/public/_lib/img/filter_all_easyer.png',
                title: 'Tutti',
                "id": 1,
                icon: "mdi-account-lock-open",
                "color": "red",
                "id_elem": "ele_tutti"
            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/approvato_easyer.png',
                title: 'Si',
                "id": 2,
                icon: "mdi-account-lock-open",
                "color": "red",
                "id_elem": "ele_si"

            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/negato.png',
                title: 'No',
                "id": 3,
                icon: "mdi-account-lock-open",
                "color": "red",
                "id_elem": "ele_no"


            },

            {
                img: 'https://app.emtool.eu/public/_lib/img/in_approv_easyer.png',
                title: 'Parziali',
                "id": 4,
                icon: "mdi-account-lock-open",
                "color": "red",
                "id_elem": "ele_parziali"


            },


        ],


        sheetPagamenti: false,

        sheetClienti: false,
        searchClienti: "",

        clienti: [],


        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    computed: {

        filteredClienti() {
            return _.orderBy(this.clienti.filter(item => {
                if (!this.searchClienti) return this.clienti;
                return (item.fld_name.toLowerCase().includes(this.searchClienti.toLowerCase()));
            }), 'headline')
        },




    },

    methods: {



        btnResetPeriodo: function () {

            window.fromPeriod = "";

            window.toPeriod = "";

            this.periodoDal = "";

            this.periodoAl = "";

            this.$modal.hide('popupPeriodo');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();

        },


        btnCancelPeriodo: function () {


            this.$modal.hide('popupPeriodo');


        },

        btnConfirmPeriodo: function () {

            window.fromPeriod = this.periodoDal;

            window.toPeriod = this.periodoAl;

            this.$modal.hide('popupPeriodo');

            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            window.table.ajax.reload();


        },

        deleteRow: async function () {


            var that = this;

            that.$swal

                .fire({
                    title: "Elimina",
                    html: "Vuoi eliminare gli elementi selezionati ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_invoiceweb.png' />",
                    cancelButtonText: "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_invoiceweb.png' />",
                    customClass: {
                        cancelButton: 'order-1',
                        confirmButton: 'order-2',
                    }

                })
                .then(async function (result) {

                    if (result.value == true) {

                        var ids = "";

                        $(".val_status").each(function (index) {

                            console.log(index);

                            if ($(this).prop("checked")) {

                                if (ids != "") {
                                    ids = ids + ",";
                                }
                                ids = ids + $(this).attr("data-id");
                            }

                        });

                        console.log("IDS SEL:", ids);



                        that.showSpinner = true;
                        that.$root.$children[0].showProgress = true;



                        var response = await apiinvoice.deleteHistoryXmlImport(
                            ids
                        ).then((res) => {

                            that.$root.$children[0].showProgress = false;
                            console.log("res from deleteHistoryXmlImport", res);

                            that.$swal({
                                icon: "success",
                                text: "Dati eliminati correttamente",
                                showConfirmButton: false,
                                timer: 2000
                            });

                            setTimeout(() => {

                                that.hideShowButtons(false);

                                window.table.ajax.reload();


                            }, 200);

                        }).catch(err => {
                            that.$root.$children[0].showProgress = false;
                            console.log(err);
                            var msg = err.response.data.Error;

                            that.$swal({
                                icon: "error",
                                text: msg,
                                showConfirmButton: false,
                                timer: 8000
                            });
                            console.log("Errori", "Non è stato possibile eliminare i dati");
                            console.log("response", response);

                        }

                        );

                    }

                });

        },


        updStatusImpXml: async function () {

            var that = this;

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });

            // that.showSpinner = true;
            // that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.updStatoImpXml(
                ids
            ).then((res) => {

                //that.$root.$children[0].showProgress = false;
                console.log("res from updStatoImpXml", res);


                setTimeout(() => {

                    window.table.ajax.reload();

                    $(".contpopupimp").show();

                    that.$modal.show('popupImp');


                }, 200);






                setTimeout(() => {

                    that.$modal.hide('popupImp');



                }, 2000);


            }).catch(err => {

                that.$modal.hide('popupMultiUpload');

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );











        },







        getNumXmlFiles: async function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.getNumXmlFiles(
                0
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getNumXmlFiles", res);

                setTimeout(() => {

                    that.showSpinner = true;
                    that.$root.$children[0].showProgress = true;

                    window.table.ajax.reload();


                    if (res.data.Result == 0) {

                        clearInterval(window.reloadRefreshFiles);

                    }

                }, 200);





            }).catch(err => {

                that.$modal.hide('popupMultiUpload');

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );











        },





        uploadDocumentsMulti: async function (v_files) {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.uploadDocumentsMulti(
                v_files
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from uploadDocumentsMulti", res);

                if (res.data.Result == "OK") {


                    that.$modal.hide('popupMultiUpload');


                    setTimeout(() => {

                        window.curStatus = "IMP";

                        window.table.ajax.reload();

                        $(".contpopupimp").show();

                        that.$modal.show('popupImp');

                        setTimeout(() => {

                            that.$modal.hide('popupImp');



                        }, 2000);


                    }, 200);




                }
                else {

                    that.$modal.hide('popupMultiUpload');

                    that.$swal({
                        icon: "error",
                        text: res.data.ErrDetails,
                        showConfirmButton: false,
                        timer: 3000
                    });

                }




            }).catch(err => {

                that.$modal.hide('popupMultiUpload');

                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );











        },



        btnCancelUploadFiles: function () {

            this.$modal.hide('popupMultiUpload');

        },

        btnConfirmUploadFiles: function () {

            console.log("ID FILE SEL: ", this.idFileSel);

            var errore = "";

            if (this.idFileSel.length == 0) {
                errore = "Selezionare almeno una fattura da caricare";
            }


            if (errore == "") {

                console.log("FILES ORIGINAL: ", window.filesDragAndDrop);

                var filesSelected = [];

                for (var xx3 = 0; xx3 < window.filesDragAndDrop.length; xx3++) {


                    var isFind = 0;
                    for (var xx4 = 0; xx4 < this.idFileSel.length; xx4++) {

                        var v_index = xx3 + 1;

                        if (parseInt(v_index) == parseInt(this.idFileSel[xx4])) {
                            isFind = 1;
                        }

                    }

                    if (isFind == 1) {

                        filesSelected.push(window.filesDragAndDrop[xx3]);

                    }


                }


                console.log("FILES SELECTED: ", filesSelected);

                setTimeout(() => {


                    this.uploadDocumentsMulti(filesSelected);

                }, 200);


            }
            else {

                this.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 4000
                });


            }




        },

        openPopupUploadDocumentsMulti: async function () {

            var that = this;

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.readInfoXmlFiles(
                window.filesDragAndDrop
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from readInfoXmlFiles", res);

                that.xmlInvoices = [];

                for (var xx = 0; xx < res.data.InfoXmlFiles.length; xx++) {

                    //console.log("XML DETAILS: ", res.data.InfoXmlFiles[xx]);

                    var v_invoice = {
                        id: res.data.InfoXmlFiles[xx].NRiga,
                        fornitore: res.data.InfoXmlFiles[xx].NomeFornitore,
                        data_fattura: res.data.InfoXmlFiles[xx].DataFattura,
                        data_scadenza: res.data.InfoXmlFiles[xx].DataScadenza,
                        totale: res.data.InfoXmlFiles[xx].Totale,
                    };

                    that.xmlInvoices.push(v_invoice);

                    that.idFileSel.push(res.data.InfoXmlFiles[xx].NRiga);


                }


                console.log("XML INVOICES: ", that.xmlInvoices);

                $(".contpopupmultiupload").show();

                that.$modal.show('popupMultiUpload');


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },


        openPopupUploadDocumentsMultiOld: async function (v_files) {


            var that = this;


            console.log("FILES: ", v_files);

            that.idFileSel = [];

            that.fileUpload = [];

            for (var xx = 0; xx < v_files.length; xx++) {


                var v_name = v_files[xx].name;
                var v_size_orig = v_files[xx].size;


                var v_size = "";

                if (v_size_orig < 1000) {
                    v_size = v_size_orig.toString() + " b";
                }

                if ((v_size_orig >= 1000) && (v_size_orig < 1000000)) {

                    v_size_orig = Math.round(v_size_orig / 1024);
                    v_size = v_size_orig.toString() + " Kb";
                }

                if ((v_size_orig >= 1000000) && (v_size_orig < 1000000000)) {

                    v_size_orig = Math.round(v_size_orig / 1024000);
                    v_size = v_size_orig.toString() + " Mb";
                }



                var v_preview = "";

                var fileExtension = v_name.split('.').pop();



                console.log("EXTENSION: ", fileExtension);


                if ((fileExtension == "png") || (fileExtension == "gif") || (fileExtension == "tiff") || (fileExtension == "jpg")) {

                    console.log("OK EXTENSION");

                    var reader = new FileReader();

                    reader.onload = function (event) {

                        console.log("OK EXTENSION ONLOAD");

                        var imageUrl = event.target.result;
                        v_preview = imageUrl;

                        var v_file = {
                            id: xx + 1,
                            name: v_name,
                            size: v_size,
                            preview: v_preview,
                            isImage: true,
                        };

                        that.fileUpload.push(v_file);

                        that.idFileSel.push(xx + 1);

                    };

                    reader.readAsDataURL(v_files[xx]);



                }
                else {


                    v_preview = this.getUrlDomain("_lib/img/usr__NM__ico__NM__icon_document_doxweb_blade_64.png");

                    var v_file = {
                        id: xx + 1,
                        name: v_name,
                        size: v_size,
                        preview: v_preview,
                        isImage: false,
                    };

                    that.fileUpload.push(v_file);

                    that.idFileSel.push(xx + 1);

                }


            }

            that.$root.$children[0].showProgress = false;


            console.log("ARRAT FILE: ", that.fileUpload);


            $(".contpopupmultiupload").show();

            that.$modal.show('popupMultiUpload');




        },




        checkFileMultiExist: async function (v_files) {


            var that = this;

            var v_file_names = "";

            for (var xx5 = 0; xx5 < v_files.length; xx5++) {


                if (v_file_names != "") {
                    v_file_names = v_file_names + ",";
                }

                v_file_names = v_file_names + v_files[xx5].name;

            }


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiinvoice.checkFileMultiExist(
                v_file_names
            ).then((res) => {

                console.log("res from checkFileExist", res);


                if (res.data.result == "OK") // il file non esiste
                {


                    console.log("ok");


                    setTimeout(() => {

                        that.openPopupUploadDocumentsMulti(v_files);

                    }, 200);


                }
                else // esiste chiedo conferma se si vuole sovrascriverlo
                {

                    that.$root.$children[0].showProgress = false;

                    var txt_msg = "I files " + res.data.file_exist + " sono già presenti si vuole sovrascriverli ?";

                    var htmlCancelButton = "<img title='Cancel' style='width:30px' src='" + this.getUrlDomain("public/_lib/img/btn_cancel3_invoiceweb.png") + "' />";

                    var htmlConfirmButton = "<img title='Confirm' style='width:30px' src='" + this.getUrlDomain("public/_lib/img/btn_confirm3_invoiceweb.png") + "' />";

                    that.$swal
                        .fire({
                            title: "Sovrascrivi",
                            html: txt_msg,
                            icon: "warning",
                            iconColor: "#8ec8a3",
                            showCancelButton: true,
                            confirmButtonText: htmlConfirmButton,
                            cancelButtonText: htmlCancelButton,
                            customClass: {
                                cancelButton: 'order-1',
                                confirmButton: 'order-2',
                            }

                        })
                        .then(async function (result) {

                            if (result.value == true) {



                                console.log("ok");


                                setTimeout(() => {

                                    that.openPopupUploadDocumentsMulti(v_files);

                                }, 200);



                            }

                        });





                }






            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );



        },


        validate() {

            var ids = "";

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    if (ids != "") {
                        ids = ids + ",";
                    }
                    ids = ids + $(this).attr("data-id");
                }

            });


            if (ids != "") {

                console.log("ok");

                this.updStatusImpXml();


            }
            else {

                this.$swal({
                    icon: "errore",
                    text: "Selezionare le fatture da importare",
                    showConfirmButton: false,
                    timer: 2000
                });

            }



        },




        validateDocumentsMulti: async function (v_files) {

            var that = this;

            console.log("FILES: ", v_files);

            window.filesDragAndDrop = v_files;

            var errore = "";

            var extAccepted = that.fileAccettati.split(",");

            for (var xx2 = 0; xx2 < v_files.length; xx2++) {

                var exten = v_files[xx2].name.split(".").pop();

                var is_find = 0;

                for (var xx3 = 0; xx3 < extAccepted.length; xx3++) {

                    var exten2 = "." + exten;

                    if (exten2 == extAccepted[xx3]) {
                        is_find = 1;
                    }

                }

                if (is_find == 0) {

                    errore = "I formati file accettati sono: " + that.fileAccettati;

                }

            }


            if (errore == "") {

                for (var xx4 = 0; xx4 < v_files.length; xx4++) {


                    if (v_files[xx4].size == 0) {

                        errore = "La grandezza del file deve essre maggiore di 0 byte";

                    }

                }

            }




            if (errore == "") {

                console.log("ok");

                that.checkFileMultiExist(v_files);


            }
            else {

                that.$swal({
                    icon: "error",
                    text: errore,
                    showConfirmButton: false,
                    timer: 4000
                });


            }




        },


        getUrlDomain: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + nome_metodo;

        },



        hideShowButtonsSelRow: function () {

            var tot = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                }

            });


            setTimeout(() => {

                var pulsantis = [];

                if (tot > 0) {

                    pulsantis.push({
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/delete-invoiceweb.png",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                        title: "Elimina",
                        width: 30


                    });

                }

                this.$root.$children[0].bnavbuttons = pulsantis;



            }, 100);



        },




        setupGrid: function () {


            var pointerVue = this;



            var filtri = `<img src='/invoiceweb/img/update-left-rotation.png' style='border:0!important;' class='selaggiorna' title='Aggiorna' /><div style="transform: translateY(-1px);margin-left:7px" title='Stato'><ion-select data-id="1" value="` + window.curStatus + `" class="selstato" interface="action-sheet" placeholder=" " cancel-text="Chiudi" title='Stato'><ion-select-option value='DAIMP'>Da Importare</ion-select-option><ion-select-option value='IMP'>Importati</ion-select-option></ion-select></div><img src='/invoiceweb/img/calendar.png' style='border:0!important;' class='selperiodo' title='Periodo' /><img src='/invoiceweb/img/fatture_fornitori2.png' style='border:0!important;' class='selfornitori' title='Fatture Fornitori' />`;




            const date = new Date().toJSON().slice(0, 10)


            $(document).ready(function () {

                var v_token = window.$cookies.get("token");


                window.columns = [

                    {
                        data: 'sel_row',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },

                    {
                        data: 'DT_RowIndex',
                        orderable: false,
                        searchable: false,
                        name: '',
                        width: '1%',
                    },


                    {
                        data: 'nome_fornitore',
                        orderable: false,
                        name: 'nome_fornitore',
                        width: '5%'
                    },

                    {
                        data: 'data_emissione',
                        orderable: false,
                        name: 'data_emissione',
                        width: '5%'
                    },

                    {
                        data: 'data_scadenza',
                        orderable: false,
                        name: 'data_scadenza',
                        width: '5%'
                    },

                    {
                        data: 'importo',
                        orderable: false,
                        name: 'importo',
                        width: '5%'
                    },



                    {
                        data: 'nuovo_fornitore',
                        orderable: false,
                        name: 'nuovo_fornitore',
                        width: '2%'
                    },


                ];





                window.table = $('#myTable').DataTable({
                    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
                        "<'rows colcontTable'<'col-sm-12 'tr>>" +
                        "<'row'<'col-sm-12 col-ok  col-md-12'i><'col-sm-12 col-ok-2  col-md-12'p>>",
                    "pagingType": "simple",
                    responsive: true,
                    "pageLength": window.perpage,

                    processing: false,
                    paging: false,
                    serverSide: false,

                    "sDom": "lfrti",

                    data: window.dataset,

                    ajax: {
                        url: pointerVue.getUrlDomain("InvoiceWebGetXmlFilesSuppliers") + '?username=' + v_token,
                        data: function (valori) {

                            console.log("VALORI:", valori);

                            try {
                                window.table.clear().draw();

                            } catch (error) {
                                console.log(error);
                            }

                            window.start = window.perpage;

                            valori.start = 0;
                            valori.length = window.perpage;
                            valori.currDay = window.currDay;
                            valori.currDayTo = window.currDayTo;
                            valori.curStatus = window.curStatus;
                            valori.curPiano = window.curPiano;

                            valori.curCustomer = window.curCustomer;
                            valori.curPayment = window.curPayment;

                            valori.dateScadDa = window.dateScadDa;
                            valori.dateScadA = window.dateScadA;

                            valori.dateEmissDa = window.dateEmissDa;
                            valori.dateEmissA = window.dateEmissA;

                            valori.curCompany = window.curCompany;

                            valori.curSrcText = window.curSrcText;

                            valori.curStatus = window.curStatus;

                            valori.fromPeriod = window.fromPeriod;
                            valori.toPeriod = window.toPeriod;

                            window.valori = valori;
                            return valori;

                        }

                    },



                    language: {

                        lengthMenu: `<label>Mostra<select name="myTable_length" aria-controls="myTable" class="custom-select custom-select-sm form-control-sm"><option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option><option value="-1">Tutti</option></select>Inserimenti</label>`,

                        paginate: {
                            next: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/next2.png'>",
                            previous: "<img style='height:15px;border:none' src='https://app.emtool.eu/_lib/img/previous2.png'>"
                        },

                        "sSearch": " ",
                        processing: ''
                    },
                    "info": "",
                    "fnDrawCallback": function (oSettings) {

                        console.log(oSettings);

                        $("body").tooltip({
                            selector: '[data-toggle="tooltip"]'
                        });
                    },

                    columns: window.columns,

                    "initComplete": function (settings) {

                        console.log(settings);

                        $("#myTable_wrapper").show();
                        $(".spin").hide();

                    },
                    "drawCallback": function (settings) {

                        console.log(settings);

                        $(".spin").hide();

                        setTimeout(() => {

                            pointerVue.showSpinner = false;
                            pointerVue.$root.$children[0].showProgress = false;


                        }, 1000);





                        var elements = $('.datetimeBook');
                        elements.each(function () {

                            var id = $(this).data("id");
                            (this).pickerOptions = {
                                buttons: [{
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'switch-btn-cancel',
                                },
                                {
                                    text: 'Done',
                                    cssClass: 'switch-btn-done',
                                    handler: columns => {
                                        console.log("ore minuti id", columns.hour.text, columns.minute.text, id);
                                        var valore = columns.hour.text + ":" + columns.minute.text;

                                        console.log(valore);

                                        $.ajax({
                                            url: pointerVue.getUrlDomain("updateHour"),
                                            data: {
                                                "id": id,
                                                "hour": valore,
                                                "v_user": "ZmFnb3N0b25p"
                                            },
                                            success: function () { }
                                        });

                                    }
                                },
                                ],
                            };
                        });

                        setTimeout(function () {

                            //   $("#myTable_filter").width($("#myTable").width());

                        }, 300);

                        var selects = $('.selectstatus');
                        selects.each(function () {
                            var id = $(this).data("id");

                            console.log("ciaone", $(this).data("id"));

                            $(this).on("ionChange", function (opt) {

                                console.log(opt);

                                $(".spin").show();
                                $.ajax({
                                    url: pointerVue.getUrlDomain("updateStatus"),
                                    data: {
                                        "id": id,
                                        "hour": this.value,
                                        "v_user": "ZmFnb3N0b25p"
                                    },
                                    success: function () {
                                        window.table._fnDraw();
                                    }
                                });

                            });

                        });

                        $(".downloadspinner").hide();

                        setTimeout(() => {

                            $("th:first").removeClass("sorting_asc");


                        }, 300);

                    }

                });

                //Events
                //START SETUP
                function throttle(f, delay) {
                    var timer = null;
                    return function () {
                        var context = this,
                            args = arguments;
                        clearTimeout(timer);
                        timer = window.setTimeout(function () {
                            f.apply(context, args);
                        },
                            delay || 500);
                    };
                }
                window.loading = 0;
                $("div.dataTables_filter input").unbind();
                $('div.dataTables_filter input').keyup(throttle(function () {
                    window.table.ajax.reload();

                }));
                $('#myTable_wrapper').on('scroll', function () {
                    if ($(this).scrollTop() + $(this).innerHeight() + 50 >= $(this)[0].scrollHeight) {
                        if (window.loading == 0) {
                            $(".spin").show();

                            setTimeout(() => {

                                $(".spin").hide();

                            }, 500);

                            var valori = window.valori;
                            valori.start = window.start;

                            $.ajax({
                                url: pointerVue.getUrlDomain("InvoiceWebGetXmlFilesSuppliers") + '?username=' + v_token,
                                data: window.valori,
                                success: function (data) {
                                    //  $(".spin").hide();
                                    window.loading = 0;
                                    window.start += window.perpage;

                                    for (let [index, val] of data.data.entries()) {
                                        console.log(index, val);
                                        window.table.row.add(val).draw(false);
                                        //  window.dataset.push(val);
                                    }
                                    console.log(data.data);
                                },
                            });
                        }
                        window.loading = 1;
                    }
                });
                //END

                var search = $(".dataTables_length").clone(true);
                search.attr("id", "final_search");
                $(".table_paginate").prepend(search);

                $("#myTable_wrapper .row .col-md-6").first().remove();

                $("#myTable_wrapper .row .col-md-6").first().removeClass("col-md-6");

                //alert (pathLocal);

                $("#myTable_filter").find("label").remove();

                $("#myTable_filter").prepend(`
                <div class="cont_buttons">

                    <div class='wrap_buttons'>

                        ` + filtri + `

                    
                         <div style='font-size:11px;padding-top: 7px;margin-left: 10px;'>Trascinare i file xml sulla tabella per caricarli</div>

                    </div>

                </div>
                <div></div>

            `);

                $('body').on('ionChange', '.selstato', function () {

                    console.log(("EVENTO CLICK SELANNO"));

                    window.curStatus = this.value;

                    pointerVue.showSpinner = true;
                    pointerVue.$root.$children[0].showProgress = true;

                    pointerVue.hideShowButtons(false);

                    window.table.ajax.reload();

                });

                $(".selricerca").on("click", function (opt) {
                    console.log(opt);

                    pointerVue.textSearch = "";

                    $(".contpopupsearch").show();

                    pointerVue.$modal.show('popupSearch');

                });



                $('body').on('click', '.selcliente', function () {

                    console.log(("EVENTO CLICK SELCLIENTE"));

                    pointerVue.sheetClienti = true;

                });




                $('body').on('click', '.inviamessaggio', function () {

                    console.log("EVENT INVIAMESSAGGIO");

                    var tot = 0;
                    var ids = "";

                    $(".val_status").each(function (index) {

                        console.log(index);

                        if ($(this).prop("checked")) {

                            if (ids != "") {
                                ids = ids + ",";
                            }

                            ids = ids + $(this).attr("data-id");

                            tot = tot + 1;

                        }

                    });

                    console.log("IDS: ", ids);

                    if (tot > 0) {

                        window.$cookies.set("id_users_doc_send", ids, "9y");

                        router.push({
                            path: "/sendMessage"
                        });

                    }

                    if (tot == 0) {

                        pointerVue.$swal({
                            icon: "error",
                            text: "Select the users to send the message to",
                            showConfirmButton: false,
                            timer: 2000
                        });


                    }



                });



                $('body').on('click', '.paginate_button', function () {
                    $(".spin").show();

                });

                $('body').on('click', '.refreshButton', function () {

                    $(".spin").show();
                    window.table._fnDraw();

                });


                $('body').on('click', '.cont_imp_xml_file_sup tr', function () {

                    console.log("TR-ACTIVE");

                    setTimeout(() => {

                        var row_data = window.table.row(this).data();

                        if (row_data != null) {


                            var last_id_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            var id_sel = $(this).find(".id_row").attr("data-id");

                            //$("tr").removeClass("active-row");

                            var set_cls_active = 0;

                            if (last_id_sel == "undefined") {
                                set_cls_active = 1;
                            } else {
                                if (last_id_sel != id_sel) {
                                    set_cls_active = 1;
                                }
                            }

                            console.log("ROM IS ACTIVE: ", set_cls_active);



                            console.log("THIS: ", this);

                            var hasClassActive = $(this).hasClass("active-row");

                            if (hasClassActive) {

                                console.log("ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", false);

                                $(this).removeClass("active-row");

                            }

                            if (!hasClassActive) {

                                console.log("NOT ACTIVE-ROW");

                                $(this).find(".val_status").prop("checked", true);

                                $(this).addClass("active-row");



                            }

                            window.id_par_sel = $("tr.active-row").find(".id_row").attr("data-id");

                            if (window.id_par_sel == undefined) {
                                window.id_par_sel = id_sel;
                            }

                            console.log("ID SELECTED: ", window.id_par_sel);

                            pointerVue.hideShowButtonsSelRow();


                        }



                    }, 100);

                });


                $(".selcambiaclientifornitori").on("ionChange", function (opt) {
                    console.log(opt);



                    if (this.value == 2) {


                        router.push({
                            path: "/fattureFornitori"
                        });



                    }


                });



                $('body').on('click', '.val_status', function (event) {
                    event.stopPropagation();

                    var checked = $(this).is(":checked");

                    console.log("CHECKED: ", checked);

                    var v_row = $(this).parent().parent();

                    console.log("V_ROW: ", v_row);

                    if (checked) {

                        $(v_row).addClass("active-row");

                        var item = window.table.row(v_row).data();

                        console.log("ITEM ROW: ", item);


                        window.id_par_sel = item.id;


                    }

                    if (!checked) {

                        $(v_row).removeClass("active-row");

                    }

                    pointerVue.hideShowButtonsSelRow();

                });



                // }

                //setup ionic calendar top
                setTimeout(function () {

                    $("body").on("ionChange", ".dateBook", function (opt) {

                        console.log(opt);
                        var date = this.value;
                        //var dateMysql = date.toJSON().slice(0, 19).replace('T', ' ')   ;
                        dateMysql = date.substr(0, 10);

                        console.log(dateMysql);

                        $.ajax({
                            url: "{{ route('smartbook.updateDate') }}",
                            data: {
                                "id": $(this).data("id"),
                                "date": dateMysql
                            },
                        });

                    });

                    $(".selectstatusall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curStatus = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    $(".selectpianiall").on("ionChange", function (opt) {
                        console.log(opt);
                        $(".spin").show();
                        window.curPiano = this.value;

                        window.table.fnPageChange(0);
                        window.table._fnDraw();

                    });

                    window.currDay = date;
                    window.currDayTo = date;

                }, 600);

                window.parentiAddedEvents = true;

            });

        },


        hideShowButtons: async function (v_enable) {

            console.log(v_enable);

            setTimeout(() => {

                var pulsantis = [];

                // if (window.curStatus == "DAIMP") {

                //     pulsantis.push({
                //         text: "Conferma",
                //         icon: "mdi-content-save",
                //         image: "https://app.emtool.eu/public/_lib/img/conferma_docsign.png",
                //         link: "/timesheet",
                //         id: "btn_confirm",
                //         disabled: false,
                //         title: "Conferma",
                //         width: 30
                //     });

                // }



                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        setupButtonsGrid: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Add",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/xbox-cross.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_add",
                        disabled: false,
                    },

                    {
                        text: "Edit",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_edit_row_16.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_edit",
                        disabled: false,
                    },

                    {
                        text: "Delete",
                        icon: "mdi-home-circle",
                        image: "https://app.emtool.eu/public/_lib/img/usr__NM__icon_delete_32.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_delete",
                        disabled: false,
                    },

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/dash",
                        id: "btn_exit",
                        disabled: false,
                    },

                ];
                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },


        setupButtons: async function () {
            setTimeout(() => {
                // console.log ("here we go" , this.$root.$children[0].drawer ) ;
                //window.App.$children[0].setToolbar();
                //   this.$root.$children[0].setToolbar();

                var pulsantis = [

                    {
                        text: "Dashboard",
                        icon: "mdi-home-circle",
                        link: "/qrconfirm",
                        id: "btn_qrcode",
                        image: "https://app.smart-book.it/public/smartbook/ICON_3.png",
                        disabled: false,
                    },

                    {
                        text: "Settings",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-settings-64.png",
                        class: "inverted",
                        link: "/settings",
                        id: "btn_settings",
                        disabled: false,
                    },

                    {
                        text: "Info",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/icons8-informazioni-482.png",
                        class: "inverted",
                        link: "/info",
                        id: "btn_info",
                        disabled: false,
                    },

                    {
                        text: "Pdf",
                        icon: "mdi-home-circle",
                        image: "https://app.smart-book.it/public/_lib/img/sb-export-pdf-50.png",
                        class: "inverted",
                        link: "/pdf",
                        id: "btn_pdf",
                        disabled: false,
                    },

                ];

                //window.App.$children[0].bnavbuttons = pulsantis;
                this.$root.$children[0].bnavbuttons = pulsantis;

                setTimeout(() => {

                }, 100);

            }, 100);
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },





    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.colHeaderDataImporto {
    width: 70px;
    color: black;
    text-align: right;
    font-weight: bold;
}

.colHeaderDataScad {
    width: 66px;
    color: black;
    font-weight: bold;
}



.colHeaderDataFatt {
    width: 66px;
    color: black;
    font-weight: bold;
}

.colHeaderForn {
    width: 130px;
    color: black;
    font-weight: bold;
}

.colHeaderNum {
    width: 25px;
    color: black;
    font-weight: bold;
}

.colHeaderCheckbox {
    width: 38px;
}

.headerTablePopup {
    display: flex;
}


.cont_imp_xml_file_sup .selstato {
    max-width: 25px !important;
    min-height: 25px !important;
    margin-left: 2px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 22px !important;
    text-indent: -119px !important;
    background-size: 25px !important;
    background-repeat: no-repeat !important;
    background-image: url(/invoiceweb/img/invoiceweb-filtri-attivi.png) !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
    margin-top: 4px !important;
}

.cont_imp_xml_file_sup .selperiodo {
    max-width: 28px !important;
    min-height: 28px !important;
    margin-left: 9px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 22px !important;
    text-indent: -119px !important;
    background-size: 28px !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
    margin-top: 1px !important;
}

.cont_imp_xml_file_sup .selfornitori {
    max-width: 28px !important;
    min-height: 28px !important;
    margin-left: 9px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 22px !important;
    text-indent: -119px !important;
    background-size: 28px !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
    margin-top: 1px !important;
}



.cont_imp_xml_file_sup .cl_checkall {
    padding-left: 1px;
    padding-top: 2px;
}

.cont_imp_xml_file_sup .checkall {
    width: 10px;
    height: 10px;
}


.cont_imp_xml_file_sup .val_status {
    width: 10px !important;
}







.cl-date {
    font-size: 10px;
    color: gray;
}


.font-row-grid-datetime {
    font-size: 10px !important;
    padding: 5px !important;
    width: 100px !important;
    padding-left: 0px !important;
}


.columnUploadFileName {
    font-weight: bold;
    width: 180px;
    margin-left: 10px;
}

.columnUploadFileSize {
    font-weight: bold;
}


.columnUploadFilePreview {
    font-weight: bold;
    margin-left: 10px;
    width: 81px;
    text-align: center;
}


.contpopupmultiupload .cl-checkbox i {
    font-size: 13px !important;
    transform: translateY(0px) !important;
}




.columnUploadFileSelect {
    font-weight: bold;
    margin-left: 3px;
    width: 39px;
    text-align: center;
}


.rowUploadFile {
    display: flex;
    height: 24px;
    align-items: center;
}


.listFile {
    overflow-y: auto;
    max-height: 280px;
    min-height: 280px;
}

.contpopupmultiupload .cl-checkbox i {
    font-size: 13px !important;
    transform: translateY(0px) !important;
}


.div-hover {
    border: 5px dashed #8ec8a3 !important;
}



.cont_imp_xml_file_sup {
    overflow-x: auto !important;
}



.cont-table .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f5cf2e;

}

.pay a {
    color: #f5cf2e;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #f5cf2e;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.selectpianiall {
    max-width: 21px;
    min-height: 22px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    /* border-radius: 12px; */
    /* min-height: 12px; */
    /* transform: translateY(-2px); */
    background-image: url(/smartb/img/FiltroNucleo.png);
    /* padding: 1px 11px; */
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    transform: translateY(2px);
}

.separator {
    min-height: 20px;
    float: left;
    border-right: 1px solid black;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 5px;
}

.cont_imp_xml_file_sup #myTable {
    margin-right: 3px;
    width: 785px !important;
}

.cont_imp_xml_file_sup table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_imp_xml_file_sup table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 25px !important;
}

.cont_imp_xml_file_sup th {
    padding-left: 5px !important;
}

.cont_imp_xml_file_sup ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_imp_xml_file_sup .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}

.cont_imp_xml_file_sup #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
    background-color: white;
}

.cont_imp_xml_file_sup div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_imp_xml_file_sup #myTable_wrapper {
    min-width: initial;
    min-width: 800px;
    max-width: 800px;
    width: 800px;
    font-size: 11px;
    color: black;
    font-weight: bold;
}


.cont_imp_xml_file_sup .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 7px;
    font-size: 10px;
    min-width: 50px;
    text-align: left;
}

.cont_imp_xml_file_sup .cl_active {
    display: inline-flex;
    align-items: center;
    min-width: 100%;
    justify-content: center;
}

.swal2-styled.swal2-cancel {
    border: 0 !important;
    border-radius: .25em !important;
    background: initial !important;
    background-color: #fff !important;
    color: #fff !important;
    font-size: 1em !important;
}

.swal2-styled.swal2-confirm {
    background-color: #fff !important;
    color: black !important;
    background: initial !important;
}



.cont_imp_xml_file_sup .inviamessaggio {
    max-width: 25px;
    min-height: 25px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.cont_imp_xml_file_sup .cont_buttons {
    display: flex !important;
    align-items: center !important;
    float: left !important;
    padding-left: 10px !important;
    justify-content: inherit !important;
    width: 100% !important;
}

.cont_imp_xml_file_sup .img_centered {
    width: 100% !important;
    text-align: center !important;
    min-width: 36px;
}


.cont_imp_xml_file_sup .cont-status {
    position: relative !important;
    overflow: hidden !important;
    width: 30px !important;
    height: 10px !important;
    margin-left: 22px !important;
}

.cont_imp_xml_file_sup .status {
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    top: -11px;
    right: -11px;
}

.cont_imp_xml_file_sup .green {
    background: green;
}

.cont_imp_xml_file_sup .yellow {
    background: #ecec06;
}

.cont_imp_xml_file_sup .red {
    background: red;
}

.cont_imp_xml_file_sup .lightblue {
    background: lightblue;
}

.cont_imp_xml_file_sup .orange {
    background: orange;
}

.cont_imp_xml_file_sup .text_right {
    width: 100% !important;
    text-align: right !important;
}

.cont_imp_xml_file_sup .text_right2 {
    width: 100% !important;
    text-align: right !important;
}


.cont_imp_xml_file_sup .selcambiaclientifornitori {
    max-width: 30px !important;
    min-height: 30px !important;
    margin-left: -5px !important;
    color: black !important;
    padding: 0px 0px !important;
    min-width: 14px !important;
    text-indent: -119px !important;
    background-size: 29px !important;
    background-repeat: no-repeat !important;
    background-image: url(/invoiceweb/img/change_invoiceweb2.png) !important;
    z-index: 2 !important;
    border: none !important;
    cursor: pointer !important;
    margin-top: 2px !important;
}

.cont_imp_xml_file_sup .selcliente {
    max-width: 28px;
    min-height: 28px;
    margin-left: -1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;

}

.cont_imp_xml_file_sup .selanticipi {
    max-width: 28px;
    min-height: 28px;
    margin-left: 1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.cont_imp_xml_file_sup .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}

.cont_imp_xml_file_sup .seleliminaanticipi {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}

.cont_imp_xml_file_sup .selscadenziario {
    max-width: 28px;
    min-height: 28px;
    margin-left: -3px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}


.cont_imp_xml_file_sup .selpagamento {
    max-width: 28px;
    min-height: 28px;
    margin-left: 1px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
}







.headerSheetClienti {
    position: fixed;
    z-index: 2;
    min-width: 100vw;
    background: white;
}

.itemCliente {
    margin-left: 15px;
}


.closePagamentiSheet {
    position: absolute;
    right: 0;
}

.c_title_pagamenti {
    text-align: left !important;
    margin-left: 44px !important;
}

.cont_imp_xml_file_sup div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em !important;
    display: inline-block !important;
    width: auto !important;
    z-index: 99999 !important;
    min-width: 30px !important;
    min-height: 30px !important;
    background: white !important;
    border-radius: 50pxv;
    border: 1px solid black !important;
}

/* .cont_imp_xml_file_sup .wrap_buttons {
    max-height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-items: center;
    width: 100%;
} */

.cont_imp_xml_file_sup .wrap_buttons {
    max-height: 40px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
}

.cont_imp_xml_file_sup .cl_valuta {
    font-weight: bold;
    text-align: center
}

.cont_imp_xml_file_sup .col_footer {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black
}

.cont_imp_xml_file_sup .col_footer_right {
    padding-top: 15px !important;
    padding-bottom: 5px !important;
    padding-left: 6px;
    border-top: 1px solid black;
    text-align: right;
    padding-right: 6px;
}



@media screen and (max-width: 960px) {

    .cont_imp_xml_file_sup .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_imp_xml_file_sup #myTable {
        /* margin-top:54px; */
    }

    .cont_imp_xml_file_sup #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_imp_xml_file_sup .cont_buttons {
        /* max-width: 218px; */
        height: 40px;
        overflow-y: hidden;
        overflow-x: auto;
        padding-left: 0px;
    }

    .cont_imp_xml_file_sup .wrap_buttons {
        max-height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .cont_imp_xml_file_sup .text_title {
        background-color: white;
        color: black;
        font-weight: bold;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 9px;
        font-size: 10px;
        min-width: 50px;
        text-align: left;
        height: 100%;
        display: flex;
        align-items: center;
    }



}

@media screen and (max-width: 1024px) {

    .contIcon {
        display: inherit;
    }

    .cont_imp_xml_file_sup .table-responsive {
        padding: 0px;
        min-width: 80vw;
        max-width: 96vw;
        left: 0px;
        position: fixed;
    }

    .cont_imp_xml_file_sup #myTable {
        max-width: 100vw !important;
        margin-right: 0px;
        min-width: 100vw !important;
        position: relative;
        width: 100vw !important;
    }

    .cont_imp_xml_file_sup #myTable_wrapper {
        min-width: initial;
        min-width: 100vw !important;
        max-width: 100vw !important;
        width: 100vw !important;
        overflow-x: hidden !important;
    }


    .cont_imp_xml_file_sup .cl_num {
        margin-left: -3px !important;
    }

    .cont_imp_xml_file_sup .cl_checkall {
        margin-left: 19px !important;
    }

    .cont_imp_xml_file_sup .cont-status {
        display: inline-flex;
    }

    .cont_imp_xml_file_sup .cl_valuta {
        display: inline;
    }

    .cont_imp_xml_file_sup .text_right {
        display: inline;
    }

    .cont_imp_xml_file_sup .img_centered {
        display: inline;
    }


    .cont_imp_xml_file_sup .col_footer {
        padding-top: 15px !important;
        padding-bottom: 5px !important;
        padding-left: 6px;
        border-top: none;
        color: white;
    }

    .cont_imp_xml_file_sup .col_footer_right {
        padding-top: 15px !important;
        padding-bottom: 5px !important;
        padding-left: 6px;
        border-top: none;
        text-align: right;
        color: white;
    }


}
</style>
