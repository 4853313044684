<template>
    <v-container fluid fill-height class="text-center d-flex align-center justify-center" style="max-width: 300px">

        <v-col v-if="isPwdChangedOnce > 0 && viewFornFatt == 1 && isSU == 0" cols="6" class="mb-15">
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn :disabled="false" v-on="on" icon id="no-background-hover" to="/vediFornitoriFatture">
                        <v-img alt="Emt logo" width="60" style="margin-top:16px" class=" " contain
                            src="@/assets/fatture_fornitori2.png" transition="scale-transition" />

                    </v-btn>
                    <div class="btnText">Fatture</div>
                </template>
                <span>Fatture</span>
            </v-tooltip>
        </v-col>



    </v-container>
</template>

<script>

import apiusers from "../utils/users/apiusers";

export default {
    name: "HelloWorld",

    mounted: function () {

        this.$root.$children[0].checkLogout();


        this.checkChangePwd();


        var a = window.$cookies.get("a");
        var b = window.$cookies.get("b");

        this.isAgila = window.$cookies.get("is_agila");
        this.isPwdChangedOnce = window.$cookies.get("is_pwd_changed_once");

        if (a == 'Y' || a == 'S' || b == 'S' || b == 'Y') {
            this.isAdmin = 1;
        }

        if (a == 'Y' || a == 'S') {
            this.isSU = 1;
        }


        this.$root.$children[0].normalDiv1 = true;
        this.$root.$children[0].normalDiv2 = true;

        this.$root.$children[0].invoiceDiv1 = false;
        this.$root.$children[0].invoiceDiv2 = false;
        this.$root.$children[0].invoiceDiv3 = false;
        this.$root.$children[0].invoiceDiv4 = false;
        this.$root.$children[0].invoiceDiv3Agila = false;

        this.$root.$children[0].calcolatorDiv1 = false;

        var namesurname = window.$cookies.get("namesurname");

        this.$root.$children[0].nameUserCurrent = namesurname;
        this.$root.$children[0].invoiceTitle = "";


        this.loadLogo();

        this.getLastLogin();

        if (this.isSU == 0) {


            this.viewSlider = this.getEnableConf("slider_invoiceweb");
            this.viewAntic = this.getEnableConf("fatt_ant_invoiceweb");
            this.viewAutom = this.getEnableConf("fatt_aut_invoiceweb");
            this.viewClienti = this.getEnableConf("fatt_cli_invoiceweb");
            this.viewClientiAgila = this.getEnableConf("fatt_cli_agila_invoicewweb");
            this.viewFornitori = this.getEnableConf("fatt_forn_invoiceweb");
            this.viewPerfor = this.getEnableConf("perf_invoiceweb");
            this.viewBanche = this.getEnableConf("banche_invoiceweb");
            this.viewCliForn = this.getEnableConf("cli_forn_invoiceweb");
            this.viewCondPag = this.getEnableConf("cond_pag_invoiceweb");
            this.viewIva = this.getEnableConf("iva_invoiceweb");
            this.viewNotif = this.getEnableConf("notif_invoiceweb");
            this.viewUM = this.getEnableConf("um_invoiceweb");
            this.viewValut = this.getEnableConf("valute_invoiceweb");
            this.viewGruppi = this.getEnableConf("grp_mail_invoiceweb");
            this.viewInvio = this.getEnableConf("inv_mail_invoiceweb");
            this.viewAbilFattVis = this.getEnableConf("abil_fatt_vis_invoiceweb");
            this.viewFattComm = this.getEnableConf("fatt_comm_invoiceweb");
            this.viewProfilo = this.getEnableConf("profile_invoiceweb");
            this.viewUtenti = this.getEnableConf("utenti_invoiceweb");
            this.viewFornFatt = this.getEnableConf("forn_fatt");


        }
        else {

            this.viewSlider = 1;
            this.viewAntic = 1;
            this.viewAutom = 1;
            this.viewClienti = 1;
            this.viewClientiAgila = 1;
            this.viewFornitori = 1;
            this.viewPerfor = 1;
            this.viewBanche = 1;
            this.viewCliForn = 1;
            this.viewCondPag = 1;
            this.viewIva = 1;
            this.viewNotif = 1;
            this.viewUM = 1;
            this.viewValut = 1;
            this.viewGruppi = 1;
            this.viewInvio = 1;
            this.viewAbilFattVis = 1;
            this.viewFattComm = 1;
            this.viewProfilo = 1;
            this.viewUtenti = 1;
            this.viewFornFatt = 1;


        }

    },

    methods: {

        getLastLogin: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");

            var response = await apiusers.getLastLoginNew(
                v_token,
                "invoice"
            ).then((res) => {

                console.log("res from getLastLoginNew", res);


                that.$root.$children[0].curDataOraLogin = res.data.Result;


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },



        checkChangePwd: async function () {

            console.log("CHECK CONF");

            var that = this;

            var v_token = window.$cookies.get("token");

            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apiusers.checkChangePwd(
                atob(v_token)
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from checkChangePwd", res);

                if (res.data.IsChanged == 0) {

                    that.$root.$children[0].logout();


                }


            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("ERrori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );


        },

        loadLogo: async function () {

            console.log("LOAD LOGO API");

            var token = window.$cookies.get("token");

            try {
                var response = await this.$api
                    .LoadLogoCompanyNew(token, "invoice")
                    .then((res) => {

                        console.log("res LoadLogoCompany: ", res);

                        this.$root.$children[0].pathLogoImage = res.data.UrlLogo;

                        // window.$cookies.set("user_models", res.data.Models, "9y");



                    });
            } catch (error) {
                console.log("err LoadLogoCompany", error);
            }

            console.log(response);



        },

        getEnableConf: function (v_codice) {

            var result = 0;

            var v_product_permissions = window.$cookies.get("product_permissions");

            var elements = v_product_permissions.split("|");

            for (var x = 0; x <= elements.length - 1; x++) {

                if (elements[x] == v_codice) {

                    result = 1;

                }


            }


            return result;

        },



    },


    computed: {
        iconFerieDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return true;
            } else {
                return false;
            }
        },
        iconMsgDisabled() {
            var issu = window.$cookies.get("a");

            if (issu == 'N' || issu == 'N') {
                return false;
            } else {
                return false;
            }
        },
    },
    data: () => ({

        viewSlider: 0,
        viewAntic: 0,
        viewAutom: 0,
        viewClienti: 0,
        viewClientiAgila: 0,
        viewFornitori: 0,
        viewPerfor: 0,
        viewBanche: 0,
        viewCliForn: 0,
        viewCondPag: 0,
        viewIva: 0,
        viewNotif: 0,
        viewUM: 0,
        viewValut: 0,
        viewGruppi: 0,
        viewInvio: 0,
        viewAbilFattVis: 0,
        viewFattComm: 0,
        viewProfilo: 0,
        viewUtenti: 0,
        viewFornFatt: 0,

        isPwdChangedOnce: 0,

        isSU: 0,

        isAgila: 0,

        isAdmin: 0,

        ecosystem: [{
            text: "vuetify-loader",
            href: "https://github.com/vuetifyjs/vuetify-loader",
        },
        {
            text: "github",
            href: "https://github.com/vuetifyjs/vuetify",
        },
        {
            text: "awesome-vuetify",
            href: "https://github.com/vuetifyjs/awesome-vuetify",
        },
        ],
        importantLinks: [{
            text: "Documentation",
            href: "https://vuetifyjs.com",
        },
        {
            text: "Chat",
            href: "https://community.vuetifyjs.com",
        },
        {
            text: "Made with Vuetify",
            href: "https://madewithvuejs.com/vuetify",
        },
        {
            text: "Twitter",
            href: "https://twitter.com/vuetifyjs",
        },
        {
            text: "Articles",
            href: "https://medium.com/vuetify",
        },
        ],
        whatsNext: [{
            text: "Explore components",
            href: "https://vuetifyjs.com/components/api-explorer",
        },
        {
            text: "Select a layout",
            href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
        },
        {
            text: "Frequently Asked Questions",
            href: "https://vuetifyjs.com/getting-started/frequently-asked-questions",
        },
        ],
    }),
};
</script>

<style>
.btnInfoUser {
    transform: translateX(25px);
}

.btnText {
    padding-top: 20px;
    visibility: hidden;
}
</style>
